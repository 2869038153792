define("discourse/plugins/discourse-saved-searches/discourse/routes/preferences-saved-searches", ["exports", "@ember/service", "discourse/routes/restricted-user"], function (_exports, _service, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _restrictedUser.default.extend({
    router: (0, _service.inject)(),
    setupController(controller, model) {
      if (!model.can_use_saved_searches) {
        return this.router.transitionTo("preferences.account");
      }
      controller.setProperties({
        model,
        savedSearches: model.saved_searches || []
      });
    }
  });
});