define("discourse/plugins/discourse-saved-searches/discourse/controllers/preferences-saved-searches", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _ajax, _ajaxError, _computed, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    savedSearches: null,
    isSaving: false,
    saved: false,
    canAddSavedSearch: (0, _computed.propertyLessThan)("savedSearches.length", "siteSettings.max_saved_searches"),
    savedSearchFrequencyOptions() {
      return [{
        name: _I18n.default.t("saved_searches.frequency_options.immediately"),
        value: "immediately"
      }, {
        name: _I18n.default.t("saved_searches.frequency_options.hourly"),
        value: "hourly"
      }, {
        name: _I18n.default.t("saved_searches.frequency_options.daily"),
        value: "daily"
      }, {
        name: _I18n.default.t("saved_searches.frequency_options.weekly"),
        value: "weekly"
      }];
    },
    addSavedSearch() {
      this.savedSearches.pushObject({
        query: "",
        frequency: "daily"
      });
    },
    removeSavedSearch(savedSearch) {
      this.savedSearches.removeObject(savedSearch);
    },
    save() {
      this.setProperties({
        isSaving: true,
        saved: false
      });
      const savedSearches = this.savedSearches.filter(savedSearch => !!savedSearch.query.trim());
      return (0, _ajax.ajax)(`/u/${this.model.username}/preferences/saved-searches`, {
        type: "PUT",
        dataType: "json",
        data: {
          searches: savedSearches
        }
      }).then(() => this.setProperties({
        saved: true,
        "model.saved_searches": savedSearches
      })).catch(_ajaxError.popupAjaxError).finally(() => this.set("isSaving", false));
    }
  }, [["method", "savedSearchFrequencyOptions", [_decorators.default]], ["method", "addSavedSearch", [_object.action]], ["method", "removeSavedSearch", [_object.action]], ["method", "save", [_object.action]]]));
});