define("discourse/plugins/discourse-saved-searches/discourse/initializers/saved-searches", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "saved-searches",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.12.0", api => {
        api.replaceIcon("notification.saved_searches.notification", "search");
      });
    }
  };
});